export default [
  {
    path: "login",
    name: "Login",
    meta: {
      title: "Login",
    },
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "escolha-divisao",
    name: "DivisaoAuth",
    meta: {
      title: "Escolha a Divisão",
    },
    component: () => import("../views/auth/Divisao.vue"),
  },
  {
    path: "recuperar-senha",
    name: "Recuperar Senha",
    meta: {
      title: "Recuperar Senha",
    },
    component: () => import("../views/auth/RecuperarSenha.vue"),
  },
  {
    path: 'resetar-senha/:token/:user',
    name: 'Resetar Senha',
    component: () => import('../views/auth/ResetarSenha.vue'),
  },
];
