
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to: any, from: any) {
        document.title =
          "Plataforma Aureana - " + to.meta.title || "Plataforma Aureana";
      },
    },
  },
};
