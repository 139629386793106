import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw, RouterView } from "vue-router";
import viewRoutes from "./views";
import authRoutes from "./auth";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Container",
    meta: {
      requiresAuth: true,
      title: "Home",
    },
    component: () => import("../components/layout/Container.vue"),
    children: viewRoutes,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "404",
    component: RouterView,
    children: authRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  localStorage.getItem("token") && store.commit("auth_success");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
