import Vuex from "vuex";
import AuthService from "@/services/auth/AuthService";
import http from "@/http";

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    nome: "",
    email: "",
    perfil: "",
    id: "",
    divisao: localStorage.getItem("divisao") || "",
  },
  mutations: {
    auth_request(state: any) {
      state.status = "loading";
    },
    auth_success(state: any) {
      state.status = "success";
      state.token = localStorage.getItem("token");
      state.nome = localStorage.getItem("nome");
      state.email = localStorage.getItem("email");
      state.perfil = localStorage.getItem("perfil");
      state.id = localStorage.getItem("id");
      http.defaults.headers.common["Authorization"] = "Bearer " + state.token;
    },
    auth_error(state: { status: string }) {
      state.status = "error";
    },
    logout(state: any) {
      state.status = "";
      state.token = "";
      state.nome = "";
      state.email = "";
      state.perfil = "";
      state.id = "";
      state.divisao = "";
      localStorage.removeItem("token");
      localStorage.removeItem("nome");
      localStorage.removeItem("perfil");
      localStorage.removeItem("email");
      localStorage.removeItem("id");
      localStorage.removeItem("divisao");
    },
    divisao(state: any) {
      state.divisao = localStorage.getItem("divisao");
    },
    remove_divisao(state: any) {
      state.divisao = "";
      localStorage.removeItem("divisao");
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        AuthService.login(user.email, user.password)
          .then((resp) => {
            if (resp['error']) {
              reject(resp['error'])
              commit("auth_error");
              return;
            }
            const token = resp.token;
            const user = resp.user;
            localStorage.setItem("token", token);
            localStorage.setItem("nome", user.nome);
            localStorage.setItem("perfil", user.perfil.nome);
            localStorage.setItem("email", user.email);
            localStorage.setItem("id", user.id);
            commit("auth_success");
            resolve(resp);
          })
          .catch((error) => {
            commit("auth_error");
            localStorage.removeItem("token");
            localStorage.removeItem("nome");
            localStorage.removeItem("email");
            localStorage.removeItem("perfil");
            localStorage.removeItem("id");
            reject("Credenciais inválidas!");
          });
      });
    },
    logout({ commit }) {
      return new Promise<void>((resolve) => {
        commit("logout");
        http.defaults.headers.common["Authorization"] = "";
        resolve();
      });
    },
    divisao({ commit }, divisao) {
      return new Promise<void>((resolve) => {
        localStorage.setItem("divisao", divisao.id);
        commit("divisao");
        resolve();
      });
    },
    remove_divisao({ commit }) {
      return new Promise<void>((resolve) => {
        commit("remove_divisao");
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    userNome: (state) => state.nome,
    userPerfil: (state) => state.perfil,
    userEmail: (state) => state.email,
    userId: (state) => state.id,
    divisaoId: (state) => state.divisao,
  },
});
