import "quasar/dist/quasar.css";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  plugins: {
    Notify,
    Dialog,
  },
  config: {
    brand: {
      primary: "#0098da",
      secondary: "#26A69A",
      accent: "#9C27B0",
      dark: "#1d1d1d",
      positive: "#4caf50",
      negative: "#f22",
      info: "#31CCEC",
      warning: "#F2C037",
    },
    notify: {
      progress: true,
      position: "top-right",
      actions: [{ label: "X", color: "white", handler: () => null }],
    },
  },
};
