import http from "@/http";

class AuthService {
  login(email: string, password: string) {
    return http
      .post("login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  recuperarSenha(data: any): Promise<any> {
    return http.post("recuperar-senha", data);
  }

  resetarSenha(token: string, email: string, data: any): Promise<any> {
    return http.post("recuperar-senha/" + token + "/" + email, data);
  }
}

export default new AuthService();
